import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

import {
  algoliaSearch,
  extractFilter,
  mergeFacets,
  serializeFacets,
  deserializeFacets,
} from '../helpers/AlgoliaClient'

import { handleFacetClick } from '../components/products/FilterPanel'
import ProductGrid from '../components/products/ProductGrid'
import Pagination from '../components/products/Pagination'
import FilterTags from '../components/products/FilterTags'
import { useDispatch } from 'react-redux'
import { hideChat, unhideChat } from '../state/popupForms'
import ShopByCategory from '../components/form/ShopByCategory'
import SortAndFilterButtonAndOptions from '../components/products/SortAndFilterButtonAndOptions'
import SortByDropDown from '../components/products/SortByDropDown'
import { IconSearch } from '../components/Icons'
import useMediaQuery from '../hooks/useMediaQuery'
import TailwindScreens from '../helpers/tailwind'
import useUrlState from '../hooks/useUrlState'
import useHashState from '../hooks/useHashState'

const SearchPage = ({ location }) => {
  const [hashQuery, setHashQuery] = useHashState('search', '')
  const [urlQuery, setUrlQuery] = useUrlState('q', '')
  const searchTerm = urlQuery || hashQuery || ''
  const setSearchTerm = term => {
    setUrlQuery('')
    setHashQuery(term)
  }
  const hasDefinedSearchQuery = (urlQuery || hashQuery || '').length > 0
  const [initialSearching, setInitialSearching] = useState(true)
  const [filterPanelOn, setFilterPanelOn] = useState(false)
  const [sortPanelOn, setSortPanelOn] = useState(false)
  const [page, setPage] = useState(0)
  const [hits, setHits] = useState([])
  const [facets, setFacets] = useState({})
  const [facetFilters, setFacetFilters] = useState(deserializeFacets(location.hash))
  const [keyword, setKeyword] = useState('')

  const [searchPages, setSearchPages] = useState({
    currentPage: 0,
    perPage: 0,
    totalCount: 0,
    itemCount: 0,
    searched: false,
  })
  const dispatch = useDispatch()
  const isMd = useMediaQuery(TailwindScreens.md)

  React.useEffect(() => {
    if(isMd) return
    if (filterPanelOn) dispatch(hideChat())
    else dispatch(unhideChat())
  }, [filterPanelOn, isMd])

  const changePage = useCallback(
    page => {
      if (!window) return
      window.location.hash = serializeFacets({
        ...deserializeFacets(window.location.hash),
        page: ['page:' + page],
      })
      window.scrollTo({ top: 0, behavior: 'smooth' })
    },
    [serializeFacets, deserializeFacets]
  )

  useEffect(() => {
    if (!window) return
    const _facets = deserializeFacets(location.hash)
    extractFilter(_facets, 'search', true)
    const _keyword = searchTerm
    const _sortBy = extractFilter(_facets, 'sort_by', false)
    let _page = parseInt(extractFilter(_facets, 'page'))
    if (_page > 0) _page = _page - 1
    if (Object.keys(_facets || {}).length === 0 && _keyword === '') {
      setInitialSearching(false)
      return
    }
    setPage(_page)
    setKeyword(_keyword)
    setFacetFilters(_facets)
    const controller = new AbortController()
    algoliaSearch(_keyword, _page, _facets, _sortBy, controller).then(searchResponse => {
      if (controller.signal.aborted) return
      setFacets(prevFacets => mergeFacets(prevFacets, _facets, searchResponse))
      setHits(searchResponse.hits)
      setSearchPages({
        currentPage: searchResponse.page,
        perPage: searchResponse.hitsPerPage,
        totalCount: searchResponse.nbHits,
        itemCount: searchResponse.hits.length,
        searched: true,
      })
      setInitialSearching(false)
    })
    return () => {
      controller.abort()
    }
  }, [
    location.hash,
    searchTerm,
    setPage,
    setKeyword,
    setFacetFilters,
    setFacets,
    mergeFacets,
    setInitialSearching,
  ])

  const onSearch = useCallback(
    e => {
      e.preventDefault()
      // if (!window) return
      // window.history.pushState(keyword, keyword, '?' + qs.stringify({ q: keyword }, ''))
      setSearchTerm(keyword)
    },
    [keyword, setSearchTerm]
  )

  const handleFacet = useCallback(
    (key, facet) => {
      if (!window) return
      window.location.hash = serializeFacets(
        handleFacetClick(key, facet, deserializeFacets(window.location.hash))
      )
    },
    [handleFacetClick, serializeFacets, deserializeFacets]
  )

  const clearFacetFilters = useCallback(() => {
    if (!window) return
    window.location.hash = '#'
    setKeyword('')
  }, [location, setKeyword])

  return (
    <Layout className={'mx-auto'} breadcrumbs={false}>
      <Seo
        title={`Search ${searchTerm} - Preowned Watches and Luxury Jewelry | Gray & Sons`}
        description={
          'Search results for ' +
          searchTerm +
          ' on Gray & Sons Jewelers. Best prices on pre-owned luxury watches, jewelry, and diamonds. Shop online or visit our showroom in Miami Beach.'
        }
        noindex={true}
      />
      <div className="grid md:grid-cols-[1fr_auto] md:flex md:space-y-3 flex-wrap px-5 lg:px-0 md:w-10/12 mx-auto">
        <div className="grow flex md:w-full whitespace-pre-wrap justify-between items-center gap-5 ">
          <h1 className="text-sm uppercase text-gray-600 pt-2 w-full md:10/12 mx-auto md:px-5">
            <span className="md:hidden inline-block"> {searchPages.totalCount} </span>Search
            results for <b className={'text-xl font-black text-black block duration-300 ' + (initialSearching  ? 'opacity-0' : 'opacity-100')}>
              {
                searchTerm || 'What are you looking for?'}</b>
          </h1>
        </div>
        <div className={'hidden w-full md:px-5 flex-row justify-between text-sm ' + (hits.length > 0? 'md:flex':'')}>
          <div className=" min-w-max md:w-1/3 lg:w-1/4 xl:w-1/5 lg:px-0 px-3">
            <button
              className={'text-gray-500 text-sm w-full text-center flex hover:text-red-700 '}
              onClick={() => {
                setFilterPanelOn(!filterPanelOn)
              }}
            >
              <div
                className={
                  (filterPanelOn
                    ? 'bg-red-700 border-red-700 '
                    : 'bg-gray-400 border-gray-400 ') +
                  ' w-10 h-6 border-2 rounded-full flex items-center mr-3 duration-150 relative '
                }
              >
                <div
                  className={
                    'h-5 w-5 absolute bg-white rounded-full ease-[cubic-bezier(.59,-0.24,.43,1.37)] duration-500 ' +
                    (filterPanelOn ? 'left-0' : 'left-4')
                  }
                />
              </div>
              FILTER{' '}
              {filterPanelOn ? (
                <>
                  ON
                  <span className="w-[0.3271875em]" />
                </>
              ) : (
                'OFF'
              )}
            </button>
          </div>

          <div className="justify-start col-span-2 basis-full md:basis-2/3 lg:basis-3/4 xl:basis-4/5 md:px-5 flex-wrap text-sm flex gap-2">
            <FilterTags
              facetFilters={facetFilters}
              clearFacetFilters={clearFacetFilters}
              handleFacet={handleFacet}
            />
          </div>
          <div className="order-3 flex flex-row justify-end items-center gap-4">
            {/* This is the total results */}
            <div className={'min-w-max flex justify-end'}>
              <b>{searchPages.totalCount}</b>
              &nbsp; Results
            </div>
            <p className="h-6 p-[0.07rem] bg-gray-400"></p>
            {/* This is sort by options on desktop */}
            <div className="min-w-max flex justify-end items-center ">
              Sort: &nbsp;
              <SortByDropDown facetFilters={facetFilters} handleFacet={handleFacet} />
            </div>
          </div>
        </div>
      </div>
      <section className="w-full md:w-10/12 justify-end mx-auto flex md:flex-row flex-col relative bg-white">
        <SortAndFilterButtonAndOptions
          hasResults={hits.length > 0}
          clearFacetFilters={clearFacetFilters}
          sortPanelOn={sortPanelOn}
          filterPanelOn={filterPanelOn}
          setFilterPanelOn={setFilterPanelOn}
          facets={facets}
          facetFilters={facetFilters}
          handleFacet={handleFacet}
          setSortPanelOn={setSortPanelOn}
        />
        <div
          className={
            (filterPanelOn
              ? 'min-h-screen h-screen md:h-full overflow-hidden opacity-0 md:block md:w-2/3 md:opacity-100 lg:w-3/4 xl:w-4/5 '
              : ' w-full ') + 'right-0 px-5 duration-300 sticky h-full top-5  '
          }
        >
          {initialSearching ? (
            <div className="@container">
              <div
                className={
                  initialSearching
                    ? ' grid my-2  grid-flow-row grid-cols-2 gap-2 @[50rem]:gap-x-[calc(1rem+2vw)] sm:gap-y-10 @[23rem]:gap-x-5 duration-200 @[120rem]:gap-x-16  sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 '
                    : 'fade-out '
                }
              >
                <div
                  className={
                    ' flex w-full flex-col gap-3 items-center py-2 lg:py-10 bg-gray-50 rounded-xl p-5 relative overflow-hidden'
                  }
                >
                  <div className={'object-fit flex w-full justify-center mix-blend-multiply '}>
                    <div className="w-full aspect-square bg-gray-100 skeleton "></div>
                  </div>
                  <div className="w-3/4 h-6 bg-gray-200 skeleton rounded-lg"></div>
                  <div className="w-2/3 h-6 bg-gray-200 skeleton rounded-lg"></div>
                  <div className="w-3/4 h-6 bg-gray-200 skeleton rounded-lg"></div>
                </div>
                <div
                  className={
                    'group flex w-full flex-col gap-3 items-center py-2 lg:py-10 bg-gray-50 rounded-xl p-5 relative overflow-hidden'
                  }
                >
                  <div className={'object-fit flex w-full justify-center mix-blend-multiply '}>
                    <div className="w-full aspect-square bg-gray-100 skeleton "></div>
                  </div>
                  <div className="w-3/4 h-6 bg-gray-200 skeleton rounded-lg"></div>
                  <div className="w-2/3 h-6 bg-gray-200 skeleton rounded-lg"></div>
                  <div className="w-3/4 h-6 bg-gray-200 skeleton rounded-lg"></div>
                </div>
                <div
                  className={
                    'group flex w-full flex-col gap-3 items-center py-2 lg:py-10 bg-gray-50 rounded-xl p-5 relative overflow-hidden'
                  }
                >
                  <div className={'object-fit flex w-full justify-center mix-blend-multiply '}>
                    <div className="w-full aspect-square bg-gray-100 skeleton "></div>
                  </div>
                  <div className="w-3/4 h-6 bg-gray-200 skeleton rounded-lg"></div>
                  <div className="w-2/3 h-6 bg-gray-200 skeleton rounded-lg"></div>
                  <div className="w-3/4 h-6 bg-gray-200 skeleton rounded-lg"></div>
                </div>
                <div
                  className={
                    'group flex w-full flex-col gap-3 items-center py-2 lg:py-10 bg-gray-50 rounded-xl p-5 relative overflow-hidden'
                  }
                >
                  <div className={'object-fit flex w-full justify-center mix-blend-multiply '}>
                    <div className="w-full aspect-square bg-gray-100 skeleton "></div>
                  </div>
                  <div className="w-3/4 h-6 bg-gray-200 skeleton rounded-lg"></div>
                  <div className="w-2/3 h-6 bg-gray-200 skeleton rounded-lg"></div>
                  <div className="w-3/4 h-6 bg-gray-200 skeleton rounded-lg"></div>
                </div>
              </div>
              <p className="text-center text-3xl py-5 text-gray-300 uppercase animate-pulse">
                {' '}
                Searching . . .
              </p>
            </div>
          ) : hasDefinedSearchQuery ? (
            <div className="animate-fade-in w-full">
              <ProductGrid
                products={hits}
                page={page}
                setPage={setPage}
                className={'w-full'}
                searchTerm={searchTerm}
              />
              {hits.length <= 0 && <ShopByCategory />}
            </div>
          ) : (
            <div>
              <form
                className="hidden min-w-max  grow justify-self-end md:block lg:pr-5"
                action={'/search'}
                method={'get'}
                onSubmit={onSearch}
              >
                <div
                  className={
                    'mx-auto my-3 hidden w-full flex-row rounded-full border border-gray-300 duration-300 hover:shadow-lg focus:shadow-lg md:flex'
                  }
                >
                  <input
                    type={'text'}
                    aria-label={'Search'}
                    name={'q'}
                    placeholder={'Search'}
                    value={keyword}
                    id={'search-keywords'}
                    onChange={e => {
                      setKeyword(e.target.value)
                    }}
                    className={
                      ' w-full rounded-l-full py-3 px-5 placeholder-gray-500 outline-0 lg:mr-5'
                    }
                  />
                  <button className={'my-auto h-10 border-l border-gray-300 px-5 text-gray-500 '}>
                    <IconSearch />
                    <span className={'sr-only'}>Search</span>
                  </button>
                </div>
              </form>
              <ShopByCategory />
            </div>
          )}
        </div>
      </section>
      {!initialSearching && hits.length > 0 && (
        <Pagination
          pageInfo={searchPages}
          offset={1}
          onClick={changePage}
          totalCount={searchPages.totalCount}
        />
      )}
    </Layout>
  )
}
SearchPage.propTypes = {
  location: PropTypes.object,

}

export default SearchPage
